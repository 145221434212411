// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

var buildVersion = (`${__webpack_hash__}`);

var assetCacheName = "icost-static-".concat(buildVersion);

var runtimeCacheName = "icost-runtime-".concat(buildVersion);

var assets = [
  "/",
  "/offline",
  "/index.html",
  "/public/manifest.json",
  "/public/images/icosticon_96.png",
  "/public/images/icosticon_192.png",
  "/public/images/icosticon_512.png"
];

function precache(param) {
  return self.caches.open(assetCacheName).then(function (cache) {
              return cache.addAll(assets);
            });
}

function fromCache(req, cacheName) {
  return self.caches.open(cacheName).then(function (cache) {
                return cache.match(req);
              }).then(function (matching) {
              if (matching == null) {
                return Promise.reject(new Error(">> no from cache: " + (cacheName + req.url)));
              } else {
                return Promise.resolve(matching);
              }
            });
}

function fromNetwork(req) {
  return fetch(req);
}

function addToCache(req, cacheName) {
  return self.caches.open(cacheName).then(function (cache) {
              return cache.add(req);
            });
}

function clearOldCaches(param) {
  return self.caches.keys().then(function (keys) {
              Belt_Array.map(Belt_Array.keep(keys, (function (key) {
                          if (key !== assetCacheName) {
                            return key !== runtimeCacheName;
                          } else {
                            return false;
                          }
                        })), (function (key) {
                      return self.caches.delete(key);
                    }));
              return Promise.resolve(undefined);
            });
}

function assetStrategy(req, cacheName) {
  var result = fromCache(new Request(req), cacheName);
  result.then(function (param) {
        return addToCache(req, cacheName);
      });
  return result;
}

function runtimeStrategy(req, cacheName) {
  var isIndexFile = function (req) {
    var reqUrl = req.url;
    return Belt_Option.isSome(Caml_option.null_to_opt(reqUrl.match(new RegExp("^(https|http).+(index." + (buildVersion + ".js)$"), "g"))));
  };
  return fetch(new Request(req)).then(function (response) {
                var status = response.status;
                if ((status / 100 | 0) === 2 && isIndexFile(req)) {
                  addToCache(req, cacheName);
                }
                return Promise.resolve(response);
              }).catch(function (param) {
              return fromCache(new Request(req), cacheName);
            });
}

self.oninstall = (function ($$event) {
    console.log(">>>! The service worker is being installed.", $$event);
    $$event.waitUntil(clearOldCaches(undefined));
    $$event.waitUntil(precache(undefined));
    
  });

self.onactivate = (function ($$event) {
    console.log(">>>! The service worker is being activate.", $$event);
    $$event.waitUntil(clearOldCaches(undefined));
    
  });

self.onfetch = (function ($$event) {
    var req = $$event.request;
    $$event.respondWith(({
            NAME: "Promise",
            VAL: runtimeStrategy(req, runtimeCacheName).catch(function (err) {
                  console.log(">>> fetch network err:", err, "request: ", req);
                  return fromCache(req, assetCacheName);
                })
          }).VAL);
    
  });

var notCacheApis = {
  hd: "sw.js",
  tl: {
    hd: "accounts/login/",
    tl: {
      hd: "models/create/",
      tl: {
        hd: "models/templates/",
        tl: {
          hd: "models/master/",
          tl: /* [] */0
        }
      }
    }
  }
};

export {
  buildVersion ,
  assetCacheName ,
  runtimeCacheName ,
  assets ,
  notCacheApis ,
  precache ,
  fromCache ,
  fromNetwork ,
  addToCache ,
  clearOldCaches ,
  assetStrategy ,
  runtimeStrategy ,
  
}
/* buildVersion Not a pure module */
